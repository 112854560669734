<template>
  <div
    class="audio-player"
    @move-current-time="onMoveCurrentTime"
  >
    <div
      v-if="status === STATUS.LOADING"
      class="loading"
    >
      <div class="position">
        <div class="loader" />
      </div>
    </div>
    <div
      v-if="status === STATUS.LOADING"
      class="overlay"
    />
    <div class="inner">
      <div class="left">
        <ButtonPlay
          :is-playing="isPlaying"
          @play="status === STATUS.UNLOADED ? load() : play()"
          @pause="pause"
        />
        <ButtonBack
          class="ml-xs"
          @click="back"
        />
        <ButtonForward
          class="ml-xs"
          @click="forward"
        />
      </div>
      <div class="center">
        <div class="current-time">
          {{ currentTimeFormatted }}
        </div>
        <Seekbar
          v-model:current-time="currentTime"
          :duration="duration"
          class="mh-xxs"
          @change="setCurrentTime"
          @mousedown="onMousedown"
          @mouseup="onMouseup"
        />
        <div class="duration">
          {{ durationFormatted }}
        </div>
      </div>
      <div class="right">
        <SelectPlaybackRate
          :playback-rate="playbackRate"
          @input="changePlaybackRate"
        />
        <VolumeControls
          class="ml-xs"
          :muted="muted"
          :volume="volume"
          @input="setVolume"
          @muteOn="muteOn"
          @muteOff="muteOff"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ButtonBack from '@/components/player/ButtonBack';
import ButtonForward from '@/components/player/ButtonForward';
import ButtonPlay from '@/components/player/ButtonPlay';
import Seekbar from '@/components/player/Seekbar';
import SelectPlaybackRate from '@/components/player/SelectPlaybackRate';
import VolumeControls from '@/components/player/VolumeControls';
import { getWavDownloadUrlApi } from '@/utils/ApiHelper';
import { ERROR_MESSAGES } from '@/utils/Constants';
import { UAParser } from 'ua-parser-js';

export default {
  components: {
    ButtonPlay,
    ButtonForward,
    ButtonBack,
    Seekbar,
    VolumeControls,
    SelectPlaybackRate,
  },
  props: {
    summaryId: {
      type: String,
      required: true,
    },
    part: {
      type: Number,
      required: true,
    },
  },
  emits: ['error'],
  data() {
    return {
      /**
       * Audioオブジェクト
       */
      audio: new Audio(),

      /**
       * 読み込みステータス
       */
      status: 'unloaded',

      /**
       * 読み込みステータス定数一覧
       */
      STATUS: {
        UNLOADED: 'unloaded',
        LOADING: 'loading',
        LOADED: 'loaded',
        ERROR: 'error',
      },

      /**
       * 巻き戻し秒数
       */
      BACK_SECONDS: 10,

      /**
       * 早送り秒数
       */
      FORWARD_SECONDS: 10,

      /**
       * 再生フラグ
       */
      isPlaying: false,

      /**
       * リトライ済みフラグ
       */
      isRetried: false,

      /**
       * 再生時間
       */
      currentTime: 0,

      /**
       * 合計時間
       */
      duration: 0,

      /**
       * 再生速度
       */
      playbackRate: '1.0',

      /**
       * ミュート
       */
      muted: false,

      /**
       * 音量
       */
      volume: 1,

      /**
       * 音量（ミュート前）
       */
      volumeBeforeMute: 1,
    };
  },
  computed: {
    currentTimeFormatted() {
      const minutes = Math.floor(this.currentTime / 60);
      const seconds = Math.floor(this.currentTime % 60);
      return `${minutes}:${seconds.toString().padStart(2, '0')}`;
    },
    durationFormatted() {
      const minutes = Math.floor(this.duration / 60);
      const seconds = Math.floor(this.duration % 60);
      return `${minutes}:${seconds.toString().padStart(2, '0')}`;
    },
    isSafari() {
      const parser = UAParser();
      return parser.browser.name === 'Safari';
    },
  },
  unmounted() {
    // 記録詳細画面から離れるとき
    // 再生を停止してイベントリスナーをすべて削除する
    this.initialize();
  },
  methods: {
    async onMoveCurrentTime(e) {
      if (this.status === this.STATUS.UNLOADED) {
        await this.load();
      } else {
        await this.play();
      }
      this.audio.currentTime = e.detail.currentTime;
    },
    /**
     * 初期化する
     */
    initialize() {
      this.audio.pause();
      this.audio.removeEventListener('timeupdate', this.timeupdateEventListener);
      this.audio.removeEventListener('ended', this.endedEventListener);
      this.audio.removeEventListener('error', this.errorEventListener);
      this.audio = new Audio();
      this.status = this.STATUS.UNLOADED;
      this.isPlaying = false;
      this.isRetried = false;
      this.currentTime = 0;
      this.duration = 0;
      // 再生速度、ミュート、音量はそのまま
    },

    /**
     * 音声データを読み込む
     */
    async load() {
      this.status = this.STATUS.LOADING;

      // 音声ダウンロードURL取得APIを呼び出す
      const { status, data } = await getWavDownloadUrlApi(
        {
          summary_id: this.summaryId,
          part: this.part,
        },
        { is_play_file: 1 },
      );

      if (status === 400 || status === 422) {
        const errorMessages = data.detail.map(item => item.msg);
        this.$emit('error', errorMessages);
        this.status = this.STATUS.UNLOADED;
        return;
      }
      this.audio.src = data.download_url;
      this.audio.load();

      // 再生可能状態になった
      this.audio.addEventListener('loadeddata', () => {
        this.duration = this.audio.duration;
        this.audio.playbackRate = this.playbackRate;
        this.audio.muted = this.muted;
        this.audio.volume = this.volume;
        this.play();
        this.status = this.STATUS.LOADED;
      }, {once: true});

      // 再生位置が変更された
      this.audio.addEventListener('timeupdate', this.timeupdateEventListener);

      // 再生が最後まで到達した
      this.audio.addEventListener('ended', this.endedEventListener);

      // リソースがエラーのために読み込めなかった
      this.audio.addEventListener('error', this.errorEventListener);
    },

    /**
     * 音声を再生する
     */
    play() {
      // 再生位置が最後まで到達したら再生させない
      if (this.currentTime === this.duration) return;
      // Safariで一瞬秒数が巻き戻されてしまう対応
      // https://kddiret.backlog.com/view/KAG_VOICE_DX-383
      // https://rch850.hatenablog.com/entry/2021/07/26/015048
      if (this.isSafari) new AudioContext();
      this.isPlaying = true;
      this.audio.play();
    },

    /**
     * 再生を一時停止する
     */
    pause() {
      this.isPlaying = false;
      this.audio.pause();
    },

    /**
     * 現在の再生時間を10秒早送り
     */
    forward() {
      if (this.status !== this.STATUS.LOADED) return;
      if (this.isSafari) {
        this.reload(Math.min(this.audio.currentTime + this.FORWARD_SECONDS, this.duration));
      } else {
        this.currentTime += this.FORWARD_SECONDS;
        this.audio.currentTime += this.FORWARD_SECONDS;
      }
    },

    /**
     * 現在の再生時間を10秒巻き戻し
     */
    back() {
      if (this.status !== this.STATUS.LOADED) return;
      if (this.isSafari) {
        this.reload(Math.max(this.audio.currentTime - this.BACK_SECONDS, 0));
      } else {
        this.currentTime -= this.BACK_SECONDS;
        this.audio.currentTime -= this.BACK_SECONDS;
      }
    },

    /**
     * 現在の再生時間を設定する
     */
    setCurrentTime(currentTime) {
      if (this.status !== this.STATUS.LOADED) return;
      if (this.isSafari) {
        this.reload(currentTime);
      } else {
        this.audio.currentTime = currentTime;
      }
    },

    /**
     * 音声データを読み込む
     */
    async reload(currentTime) {
      this.status = this.STATUS.LOADING;
      this.audio.pause();

      // 音声ダウンロードURL取得APIを呼び出す
      const { status, data } = await getWavDownloadUrlApi(
        {
          summary_id: this.summaryId,
          part: this.part,
        },
      );
      if (status === 400 || status === 422) {
        const errorMessages = data.detail.map(item => item.msg);
        this.$emit('error', errorMessages);
        this.status = this.STATUS.ERROR;
        return;
      }
      this.audio.src = data.download_url;
      this.currentTime = currentTime;
      this.audio.currentTime = currentTime;
      this.audio.load();

      // 再生可能状態になった
      this.audio.addEventListener('loadeddata', () => {
        this.duration = this.audio.duration;
        this.audio.playbackRate = this.playbackRate;
        this.audio.muted = this.muted;
        this.audio.volume = this.volume;
        this.isRetried = false;
        if (this.isPlaying) this.audio.play();
        this.status = this.STATUS.LOADED;
      }, {once: true});
    },

    /**
     * timeupdateイベントのイベントリスナー
     * 表示されている現在の再生時間を更新する
     */
    timeupdateEventListener() {
      this.currentTime = this.audio.currentTime;
    },

    /**
     * シークバーのドットをスライドしている間は
     * 表示されている現在の再生時間の更新を停止する
     */
    onMousedown() {
      this.audio.removeEventListener('timeupdate', this.timeupdateEventListener);
    },

    /**
     * シークバーのドットのスライドが完了したら
     * 表示されている現在の再生時間の更新を開始する
     */
    onMouseup() {
      this.audio.addEventListener('timeupdate', this.timeupdateEventListener);
    },

    /**
     * errorイベントのイベントリスナー
     */
    async errorEventListener() {
      // リトライ済みかどうか
      if (this.isRetried) {
        // リトライ済みの場合はエラーメッセージを表示
        console.error('error', this.audio.error);
        this.status = this.STATUS.ERROR;
        this.$emit('error', [ERROR_MESSAGES.AUDIO_CANNOT_PLAY]);
      } else {
        // リトライしていない場合はリトライ実行
        this.isRetried = true;
        this.reload(this.currentTime);
      }
    },

    /**
     * endedイベントのイベントリスナー
     */
    endedEventListener() {
      this.isPlaying = false;
    },

    /**
     * 再生速度を変更する
     */
    changePlaybackRate(playbackRate) {
      this.audio.playbackRate = playbackRate;
      this.playbackRate = playbackRate;
    },

    /**
     * ミュートする
     */
    muteOn() {
      this.audio.muted = true;
      this.muted = true;
      this.volumeBeforeMute = this.volume;
      this.audio.volume = 0;
      this.volume = 0;
    },

    /**
     * ミュート解除する
     */
    muteOff() {
      this.audio.muted = false;
      this.muted = false;
      if (this.volumeBeforeMute == 0) {
        this.audio.volume = 1;
        this.volume = 1;
      } else if (this.volumeBeforeMute > 0) {
        this.audio.volume = this.volumeBeforeMute;
        this.volume = this.volumeBeforeMute;
      }
    },

    /**
     * 音量を設定する
     */
    setVolume(volume) {
      this.audio.volume = volume;
      this.volume = volume;
      if (volume == 0) {
        this.audio.muted = true;
        this.muted = true;
        this.volumeBeforeMute = 0;
      } else if (volume > 0) {
        this.audio.muted = false;
        this.muted = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.loading {
  position: fixed;
  left: 0;
  bottom: 0;
  height: 54px;
  width: 100%;
  z-index: 20;
  > .position {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    > .loader {
      width: 32px;
      height: 32px;
      margin: 0 auto;
      box-sizing: border-box;
      border-radius: 50%;
      border-top: 3px solid $black600;
      border-right: 3px solid $black600;
      border-bottom: 3px solid $black600;
      border-left: 3px solid $gray300;
      animation: load 1.1s infinite linear;
    }

    @keyframes load {
      0% {
        transform: rotate(0deg);
      }

      100% {
        transform: rotate(360deg);
      }
    }
  }
}

.overlay {
  position: fixed;
  left: 0;
  bottom: 0;
  height: 54px;
  width: 100%;
  z-index: 10;
  background: $white100;
  opacity: 0.6;
}

.audio-player {
  position: fixed;
  left: 0;
  bottom: 0;
  height: 54px;
  width: 100%;
  background: $gray300;
  > .inner {
    height: 100%;
    width: 1080px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;

    > .left, .center, .right {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .current-time, .duration {
      font: $sans-none-12;
      color: $black600;
      width: 36px;
    }
    .current-time {
      text-align: right;
    }
    .duration {
      text-align: left;
    }
  }
}
</style>
