<template>
  <LayoutMain @refresh="init">
    <div class="body">
      <div v-if="flashMessages.messages.length > 0">
        <MessageBar
          v-if="flashMessages.type == 'error'"
          :type="flashMessages.type"
          :messages="flashMessages.messages"
          class="mt-xs mr-xs ml-xs"
        />
        <MessageSearchResult
          v-if="flashMessages.type == 'success'"
          :message="flashMessages.messages"
          :query="flashMessages.query"
          class="mt-xs mr-xs ml-xs"
        />
      </div>

      <h1 class="header-title">
        アーカイブダウンロード
      </h1>

      <div class="search-form">
        <div class="search-form__block">
          <FormBlock
            label="取り出し期間"
            class="form-block-row"
          >
            <FormDatePicker
              v-model="periodFrom"
              :clearable="true"
              size="width-180"
            />
            <div class="wave">
              ~
            </div>
            <FormDatePicker
              v-model="periodTo"
              :clearable="true"
              size="width-180"
            />
          </FormBlock>
          <Button
            size="inline"
            :disabled="!periodFrom || !periodTo"
            @click="onSearchSubmit"
          >
            取り出し
          </Button>
        </div>
      </div>

      <MessageBar
        type="info"
        :messages="[
          'アーカイブの取り出しは年に2回までとなります。',
          !downloadList.date_msg ? '' : 'ダウンロードデータは' + downloadList.date_msg + '日を過ぎると自動的に削除されます。',
          '失敗ステータスのデータはダウンロードできません。再度、ダウンロードデータ作成からやり直してください。',
        ]"
        class="mt-xs mr-xs mb-xs ml-xs"
      />

      <ListDownload
        :items="downloadList.items"
        @download="downloadZip"
        @request="openDownloadRequestModal"
      />
    </div>
  </LayoutMain>

  <ModalDownloadConfirmRequest
    ref="modalDownloadConfirmRequest"
    @request="downloadRequest"
  />
</template>

<script>
import ListDownload from '@/components/archive/ListDownload.vue';
import Button from '@/components/button/Button.vue';
import MessageBar from '@/components/common/MessageBar';
import MessageSearchResult from '@/components/common/MessageSearchResult';
import FormBlock from '@/components/form/FormBlock';
import FormDatePicker from '@/components/form/FormDatePicker';
import LayoutMain from '@/components/layout/LayoutMain.vue';
import ModalDownloadConfirmRequest from '@/components/modal/ModalDownloadConfirmRequest';
import { getArchiveDownloadDataApi, getArchiveDownloadUrlApi, postArchiveExtractApi, postCreateArchiveApi } from '@/utils/ApiHelper';
import { mapActions } from 'vuex';

export default {
  components: {
    LayoutMain,
    MessageBar,
    MessageSearchResult,
    ListDownload,
    ModalDownloadConfirmRequest,
    FormBlock,
    FormDatePicker,
    Button,
  },
  data() {
    return {
      /**
       * 検索内容
       */
      searchForm: {
        period_from: '',
        period_to: '',
      },

      /**
       * 取り出し期間
       */
      periodFrom: undefined,
      periodTo: undefined,

      /**
       * ダウンロードデータ一覧
       */
      downloadList: {
        date_msg: '',
        items: [],
      },

      /**
       * ダウンロードデータ一覧画面に表示するフラッシュメッセージ
       */
      flashMessages: {
        type: '',
        messages: [],
        query: '',
      },
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    ...mapActions(['startLoading', 'stopLoading']),

    /**
     * 初期表示処理
     */
    async init() {
      this.startLoading();

      // ダウンロードデータ一覧取得
      await this.fetchData();

      this.stopLoading();
    },

    /**
     * ダウンロードデータ一覧取得
     */
    async fetchData() {
      // フラッシュメッセージクリア
      this.flashMessages.type = '';
      this.flashMessages.messages = [];
      this.flashMessages.query = '';

      // アーカイブ音声ファイルダウンロードデータ作成状況取得APIの呼び出し
      const { status, data } = await getArchiveDownloadDataApi();

      // 業務エラーの場合はエラーメッセージを設定して処理終了
      if (status === 400) {
        this.flashMessages.type = 'error';
        this.flashMessages.messages = data.detail.map(item => item.msg);
        this.stopLoading();
        return true;
      }

      // ダウンロードデータ一覧を設定
      this.downloadList.date_msg = data.date_msg;
      this.downloadList.items = data.data;

      return false;
    },

    /**
     * 検索ボタンの処理
     */
    async onSearchSubmit() {
      this.startLoading();

      // 検索条件（取り出し期間）を指定
      this.searchForm.period_from = this.periodFrom;
      this.searchForm.period_to = this.periodTo;

      // アーカイブ音声ファイル取り出しAPIをリクエスト
      await this.onArchiveExtract();

      this.stopLoading();
    },

    /**
     * アーカイブ音声ファイル取り出しAPIをリクエスト
     */
    async onArchiveExtract() {
      // フラッシュメッセージクリア
      this.flashMessages.type = '';
      this.flashMessages.messages = [];
      this.flashMessages.query = '';

      // アーカイブ音声ファイル取り出しAPIの呼び出し
      const { status, data } = await postArchiveExtractApi(this.searchForm);

      // 業務エラーまたはバリデーションエラーの場合はエラーメッセージを設定して処理終了
      if (status === 400 || status === 422) {
        this.flashMessages.type = 'error';
        this.flashMessages.messages = data.detail.map(item => item.msg);
        this.stopLoading();
        return;
      }

      // ダウンロードデータ一覧を再取得
      if(await this.fetchData()) {
        this.stopLoading();
        return;
      }

      // 成功メッセージを表示する
      this.flashMessages.type = 'success';
      this.flashMessages.messages = data.detail.map(item => item.msg)[0];
      this.flashMessages.query = data.detail.map(item => item.query)[0];

      this.stopLoading();
    },

    /**
     * ダウンロードリクエスト確認モーダル表示
     * @param {*} id ダウンロードデータID
     */
    async openDownloadRequestModal(id) {
      // モーダル表示
      this.$refs.modalDownloadConfirmRequest.openModal(id);
    },

    /**
     * ダウンロードリクエスト
     * @param {*} id ダウンロードデータID
     */
    async downloadRequest(id) {
      // モーダル非表示
      this.$refs.modalDownloadConfirmRequest.hideModal();

      this.startLoading();

      // フラッシュメッセージクリア
      this.flashMessages.type = '';
      this.flashMessages.messages = [];
      this.flashMessages.query = '';

      // アーカイブ音声ファイルダウンロードデータ作成APIの呼び出し
      const { status, data } = await postCreateArchiveApi(
        {
          archive_download_id  : id,
        },
      );

      // 業務エラーまたはバリデーションエラーの場合はエラーメッセージを設定して処理終了
      if (status === 400 || status === 422) {
        this.flashMessages.type = 'error';
        this.flashMessages.messages = data.detail.map(item => item.msg);
        this.stopLoading();
        return;
      }

      // ダウンロードデータ一覧を再取得
      if(await this.fetchData()) {
        this.stopLoading();
        return;
      }

      this.stopLoading();
    },

    /**
     * ZIPダウンロード
     * @param {*} id ダウンロードデータID
     */
    async downloadZip(id) {
      this.startLoading();

      // メッセージクリア
      this.flashMessages.type = '';
      this.flashMessages.messages = [];
      this.flashMessages.query = '';

      // アーカイブ音声ファイルダウンロードURL取得APIの呼び出し
      const { status, data } = await getArchiveDownloadUrlApi(
        {
          archive_download_id  : id,
        },
      );

      // レスポンスのメッセージ、業務エラーまたはバリデーションエラーの場合はエラーメッセージを設定して処理終了
      if (status === 400 || status === 422 || data.detail) {
        this.flashMessages.type = 'error';
        this.flashMessages.messages = data.detail.map(item => item.msg);
        this.stopLoading();
        return;
      }

      // ファイルダウンロード
      const link = document.createElement('a');
      link.href = data.archive_download_url;
      link.click();

      this.stopLoading();
    },
  },
};
</script>

<style lang="scss" scoped>
.body {
  display: flex;
  flex-direction: column;
  height: 100%;

  > .page-heading {
    margin: $spacing-xs $spacing-xs 0;
  }
}

.header-title {
  font: $sans-snug-20-bold;
  color: $black700;
  height: 32px;
  margin: $spacing-xs $spacing-xs 0;
}

.search-form {
  padding-bottom: $spacing-xs;
  margin: 0 $spacing-xs;
  border-bottom: 1px solid $gray500;
  &__block {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    margin-top: $spacing-xs;
    > .form-block {
      margin-right: $spacing-xs;
      &:last-of-type {
        margin-right: 0;
      }
    }
    > .form-block-row {
      :deep(.form-item) {
        display: flex;
        width: 100%;
        .wave {
          display: flex;
          align-items: center;
          color: $black700;
          width: 8px;
          margin-left: 4px;
          margin-right: 4px;
          font: $sans-none-14;
        }
      }
      .form-time-picker {
        margin-left: 4px;
      }
    }
    > .button{
      margin-left: $spacing-xs;
      margin-bottom: 2px;
    }
  }
}
</style>
