<template>
  <LayoutMain @refresh="init">
    <div class="main-header">
      <MessageBar
        v-if="flashMessages.messages.length > 0"
        :type="flashMessages.type"
        :messages="flashMessages.messages"
        class="message-area"
      />
      <MessageBar
        v-if="errorMessages.length > 0"
        :messages="errorMessages"
        type="error"
        class="message-area"
      />
      <MessageBar
        v-if="successMessages.length > 0"
        :messages="successMessages"
        type="success"
        class="message-area"
      />
      <SearchMessageBox
        v-if="result.messages.length > 0"
        :messages="result.messages"
        :description="result.description"
        :disabled="!result.isDownloadable"
        :download-msg="result.downloadMsg"
        :is-show-download-btn="hasAuthDownloadSearchAPI"
        class="message-area"
        @download="checkDownloadData"
      />
      <SearchField
        v-model="searchField.searchWord"
        class="search-area"
        @enter="searchFullText"
        @open="$refs.modalVoiceSearch.openModal()"
      />
    </div>
    <TableListVoice
      v-model:checkedIds="checkedIds"
      :records="result.items"
      :total-record="result.totalRecord"
      :is-asc="query.isAsc"
      :page="query.page"
      :is-show-checkbox="hasAuthDeleteRecordListAPI || hasAuthDownloadSelectAPI"
      @sortAsc="sortAsc"
      @sortDesc="sortDesc"
      @prevPage="prevPage"
      @nextPage="nextPage"
      @toDetailPage="toDetailPage"
    />
    <ActionBarBottom
      v-if="checkedIds.length"
      :type="isUseArchive ? 'download' : 'download-and-delete'"
      :text="checkedIds.length + '件の記録を選択'"
      :btn-text="'ゴミ箱に入れる'"
      :can-select-download="hasAuthDownloadSelectAPI"
      @download="checkDownloadData"
      @delete="$refs.modalVoiceDelete.openModal()"
      @cancel="() => checkedIds = []"
    />
  </LayoutMain>

  <ModalVoiceSearch
    ref="modalVoiceSearch"
    v-model:base-id="input.baseId"
    v-model:calling-number="input.callingNumber"
    v-model:incoming-number="input.incomingNumber"
    v-model:search-word="input.searchWord"
    v-model:is-and="input.isAnd"
    v-model:search-period-from="input.searchPeriodFrom"
    v-model:search-period-to="input.searchPeriodTo"
    v-model:line-contract="input.lineContract"
    v-model:has-hikari-direct="input.hasHikariDirect"
    v-model:has-au-on="input.hasAuOn"
    v-model:has-webex-calling="input.hasWebexCalling"
    v-model:has-cloud-calling="input.hasCloudCalling"
    v-model:has-mobile-au="input.hasMobileAu"
    :errors="modalErrorMessages"
    :line-contract-options="lineContractOptions"
    :base-options="basesNameOptions"
    :is-show-line-contract="hasAuthGetLinecontractListAPI"
    @search="searchDetail"
  />

  <ModalDownloadConfirmCreation
    ref="modalDownloadConfirmCreation"
    :msg="modalDownloadConfirmCreationMessages"
    :is-select-download="isSelectDownload"
    @create="createDownloadData"
  />
  
  <ModalDownloadUnableCreation
    ref="modalDownloadUnableCreation"
    :is-download-limit="isDownloadLimit"
    :is-all-receiving="isAllReceiving"
    @move="moveDownloadDataPage"
  />

  <ModalDownloadCompleteCreation
    ref="modalDownloadCompleteCreation"
    @close="closeCompleteModal"
    @move="moveDownloadDataPage"
  />

  <ModalVoiceDelete
    ref="modalVoiceDelete"
    @delete="deleteRecords"
  />
</template>

<script>
import MessageBar from '@/components/common/MessageBar';
import SearchMessageBox from '@/components/common/SearchMessageBox';
import LayoutMain from '@/components/layout/LayoutMain';
import ModalDownloadCompleteCreation from '@/components/modal/ModalDownloadCompleteCreation';
import ModalDownloadConfirmCreation from '@/components/modal/ModalDownloadConfirmCreation';
import ModalDownloadUnableCreation from '@/components/modal/ModalDownloadUnableCreation';
import ModalVoiceDelete from '@/components/modal/ModalVoiceDelete';
import ModalVoiceSearch from '@/components/modal/ModalVoiceSearch';
import SearchField from '@/components/search/SearchField';
import ActionBarBottom from '@/components/voice/ActionBarBottom';
import TableListVoice from '@/components/voice/TableListVoice';
import { checkSearchDownloadApi, checkSelectDownloadApi, createSearchDownloadApi, createSelectDownloadApi, deleteRecordsApi, getBasesNameApi, getLineContractsApi, getRecordsApi, getUserRoleAuthsApi } from '@/utils/ApiHelper';
import { hasAuthorization, toSnakeCaseObject } from '@/utils/CommonUtil';
import { API_IDS, DOWNLOAD_TYPE, RECORD_SEARCH_TYPE } from '@/utils/Constants';
import { getIdToken } from '@/utils/IdTokenManager';
import { mapActions, mapGetters } from 'vuex';

function initialState() {
  return {
    /**
     * 詳細検索条件
     */
    input: {
      baseId: '',
      callingNumber: '',
      incomingNumber: '',
      searchWord: '',
      isAnd: true,
      searchPeriodFrom: '',
      searchPeriodTo: '',
      lineContract: '',
      hasHikariDirect: false,
      hasAuOn: false,
      hasWebexCalling: false,
      hasCloudCalling: false,
      hasMobileAu: false,
    },

    /**
     * 通常検索キーワード
     */
    searchField: {
      searchWord: '',
    },

    /**
     * 検索結果
     */
    result: {
      totalRecord: '',
      items: [],
      messages: [],
      description: '',
      isDownloadable: false,
      downloadMsg: '',
    },

    /**
     * 記録選択チェック一覧
     */
    checkedIds: [],

    /**
     * 記録一覧画面に表示するフラッシュメッセージ
     */
    flashMessages: {
      type: '',
      messages: [],
    },

    /**
     * 記録一覧画面に表示するエラーメッセージ
     */
    errorMessages: [],

    /**
     * 記録一覧画面に表示する成功メッセージ
     */
    successMessages: [],

    /**
     * 詳細検索モーダルに表示するエラーメッセージ
     */
    modalErrorMessages: [],

    /**
     * グループ名一覧
     */
    basesNameOptions: [],

    /**
     * 回線契約管理番号一覧
     */
    lineContractOptions: [],

    /**
     * 権限一覧
     */
    authorizations: [],

    /**
     * ダウンロードデータ作成確認モーダルに表示するメッセージ
     */
    modalDownloadConfirmCreationMessages: '',

    /**
     * ダウンロードデータ上限フラグ
     */
    isDownloadLimit: false,

    /**
     * 全て受信中フラグ
     */
    isAllReceiving: false,

    /**
     * 選択ダウンロードフラグ
     */
    isSelectDownload: null,
  };
}

export default {
  components: {
    MessageBar,
    SearchMessageBox,
    LayoutMain,
    ModalVoiceDelete,
    ModalVoiceSearch,
    ModalDownloadConfirmCreation,
    ModalDownloadUnableCreation,
    ModalDownloadCompleteCreation,
    SearchField,
    ActionBarBottom,
    TableListVoice,
  },
  data() {
    return initialState();
  },
  computed: {
    ...mapGetters([
      'query',
      'searchType',
      'isRestore',
      'storeMessages',
    ]),

    /**
     * 回線契約管理番号取得APIの権限チェック
     *
     * 権限が無い場合
     * ・初期処理で呼び出さない
     * ・詳細検索の回線契約管理番号を非表示
     */
    hasAuthGetLinecontractListAPI() {
      return hasAuthorization(this.authorizations, API_IDS.GET_LINECONTRACT_LIST);
    },

    /**
     * 記録削除APIの権限チェック
     *
     * 権限が無い場合
     * ・すべて選択を非表示
     * ・各記録のチェックボックスを非表示
     */
    hasAuthDeleteRecordListAPI() {
      return hasAuthorization(this.authorizations, API_IDS.DELETE_RECORD_LIST);
    },

    /**
     * 選択ダウンロード作成APIの権限チェック
     *
     * 権限が無い場合
     * ・すべて選択を非表示
     * ・各記録のチェックボックスを非表示
     * ・フローティングメニューのダウンロードボタンを非表示
     */
    hasAuthDownloadSelectAPI() {
      return hasAuthorization(this.authorizations, API_IDS.DOWNLOAD_SELECT);
    },

    /**
     * 検索ダウンロード作成APIの権限チェック
     *
     * 権限が無い場合
     * ・検索結果のダウンロードボタンを非表示
     */
    hasAuthDownloadSearchAPI() {
      return hasAuthorization(this.authorizations, API_IDS.DOWNLOAD_SEARCH);
    },

    /**
     * アーカイブ契約の有無判定 [true:有, false:無]
     *
     * 契約有りの場合
     * ・ゴミ箱に入れるボタンを非表示
     */
    isUseArchive() {
      return getIdToken('useArchive') === 'true';
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    ...mapActions([
      'startLoading',
      'stopLoading',
      'setQuery',
      'setSearchType',
      'setRestoreFlagOff',
      'clearStoreMessages',
      'setCurrentIndex',
      'setUserRoleAuths',
    ]),

    /**
     * 初期表示処理
     *
     * - 初期表示
     * - トップナビゲーション
     * - サイドナビゲーション
     */
    async init() {
      this.startLoading();

      // dataの初期化
      Object.assign(this.$data, initialState());
      // 権限取得
      await this.getUserRoleAuths();

      // 記録一覧を取得
      if (this.isRestore) {
        // 詳細画面の一覧に戻るまたは削除から遷移した場合はストアから検索条件を復元
        if (this.searchType === RECORD_SEARCH_TYPE.FULL_TEXT) {
          this.searchField.searchWord = this.query.searchWord;
        } if (this.searchType === RECORD_SEARCH_TYPE.DETAIL) {
          this.input.baseId = this.query.baseId;
          this.input.callingNumber = this.query.callingNumber;
          this.input.incomingNumber = this.query.incomingNumber;
          this.input.searchWord = this.query.searchWord;
          this.input.isAnd = this.query.isAnd;
          this.input.searchPeriodFrom = this.query.searchPeriodFrom;
          this.input.searchPeriodTo = this.query.searchPeriodTo;
          this.input.lineContract = this.query.lineContract;
          this.input.hasHikariDirect = this.query.hasHikariDirect;
          this.input.hasAuOn = this.query.hasAuOn;
          this.input.hasWebexCalling = this.query.hasWebexCalling;
          this.input.hasCloudCalling = this.query.hasCloudCalling;
          this.input.hasMobileAu = this.query.hasMobileAu;
        }
        this.setRestoreFlagOff();
        await this.fetchRecords(this.query, this.searchType);
      } else {
        // その他はデフォルト条件で記録一覧を取得
        await this.fetchRecords({
          isDeleted: false,
          isAsc: false,
          page: 1,
        }, null);
      }

      // 回線契約管理番号を取得する
      if (this.hasAuthGetLinecontractListAPI) {
        await this.fetchLineContractOptions();
      }
      // グループ名一覧を取得する
      await this.fetchBasesName();

      // 記録詳細画面で格納したストアメッセージがあれば表示する
      this.flashMessages = this.storeMessages;
      this.clearStoreMessages();

      this.stopLoading();
    },

    /**
     * 権限取得
     */
    async getUserRoleAuths() {
      const { data } = await getUserRoleAuthsApi({
        api_ids: [
          API_IDS.GET_LINECONTRACT_LIST,
          API_IDS.DELETE_RECORD_LIST,
          API_IDS.DOWNLOAD_SELECT,
          API_IDS.DOWNLOAD_SEARCH,
          API_IDS.GET_DOWNLOAD_DATA,
        ],
      });
      this.authorizations = data.data;

      // 権限をストアに保存
      this.setUserRoleAuths({
        userRoleAuths: this.authorizations,
      });
    },

    /**
     * 回線契約管理番号を取得する
     */
    async fetchLineContractOptions() {
      const { data } = await getLineContractsApi();
      const options = data.data.map(item => (
        {
          id: item.d_no,
          label: `${item.line_type}（${item.d_no}）`,
        }
      ));
      const defaultOption = [{ id: '', label: '選択してください' }];
      this.lineContractOptions = defaultOption.concat(options);
    },

    /**
     * グループ名一覧取得
     */
    async fetchBasesName() {
      const { data } = await getBasesNameApi();
      this.basesNameOptions = data.data.map(item => (
        {
          id: item.base_id,
          label: item.base_name,
        }
      ));
      this.basesNameOptions.unshift({id: '', label: '選択してください'});
    },

    /**
     * 記録一覧を取得する
     *
     * @param {*} query 検索条件
     * @param {*} searchType 検索種別
     * @param {*} isModal 詳細検索モーダルからの検索かどうか
     */
    async fetchRecords(query, searchType, isModal = false) {
      // メッセージクリア
      this.flashMessages.type = '';
      this.flashMessages.messages = [];
      this.successMessages = [];
      this.errorMessages = [];
      this.modalErrorMessages = [];

      // 記録一覧取得APIを呼び出す
      const { status, data } = await getRecordsApi(toSnakeCaseObject(query));

      // 業務エラーまたはバリデーションエラーの場合はエラーメッセージを設定して処理終了
      if (status == 400 || status == 422) {
        if (isModal) {
          this.modalErrorMessages = data.detail.map(item => item.msg);
        } else {
          this.errorMessages = data.detail.map(item => item.msg);
        }
        return;
      }

      // 記録一覧クリア
      this.result.totalRecord = '';
      this.result.items = [];
      this.result.messages = [];
      this.result.description = '';
      this.result.isDownloadable = false;
      this.result.downloadMsg = '';
      this.checkedIds = [];

      // 検索結果を設定
      this.result.totalRecord = data.total_record;
      this.result.items = data.data;
      // 検索した場合は検索結果メッセージを設定
      if (searchType) {
        this.result.messages = [data.detail[0].msg];
        this.result.description = data.detail[0].query;
        this.result.isDownloadable = data.detail[0].is_downloadable;
        this.result.downloadMsg = data.detail[0].download_msg;
      }

      // 検索条件をストアに保存
      this.setQuery({ query });
      this.setSearchType({ searchType });

      // 詳細検索モーダルを閉じる
      this.$refs.modalVoiceSearch?.hideModal();
    },

    /**
     * 通常検索
     */
    async searchFullText() {
      this.startLoading();
      await this.fetchRecords({
        isDeleted: false,
        searchWord: this.searchField.searchWord,
        isAsc: this.query.isAsc,
        page: 1,
      }, RECORD_SEARCH_TYPE.FULL_TEXT);
      this.stopLoading();
    },

    /**
     * 詳細検索
     */
    async searchDetail() {
      this.startLoading();
      await this.fetchRecords({
        baseId: this.input.baseId,
        callingNumber: this.input.callingNumber,
        incomingNumber: this.input.incomingNumber,
        searchWord: this.input.searchWord,
        isAnd: this.input.isAnd,
        searchPeriodFrom: this.input.searchPeriodFrom,
        searchPeriodTo: this.input.searchPeriodTo,
        lineContract: this.input.lineContract,
        hasHikariDirect: this.input.hasHikariDirect,
        hasAuOn: this.input.hasAuOn,
        hasWebexCalling: this.input.hasWebexCalling,
        hasCloudCalling: this.input.hasCloudCalling,
        hasMobileAu: this.input.hasMobileAu,
        isDeleted: false,
        isAsc: this.query.isAsc,
        page: 1,
      }, RECORD_SEARCH_TYPE.DETAIL, true);
      this.stopLoading();
    },

    /**
     * 並び順：古い
     * 通話開始日時の古い順に並び替える
     */
    async sortAsc() {
      this.startLoading();
      await this.fetchRecords({
        ...this.query,
        ...{
          isAsc: true,
          page: 1,
        },
      }, this.searchType);
      this.stopLoading();
    },

    /**
     * 並び順：新しい
     * 通話開始日時の新しい順に並び替える
     */
    async sortDesc() {
      this.startLoading();
      await this.fetchRecords({
        ...this.query,
        ...{
          isAsc: false,
          page: 1,
        },
      }, this.searchType);
      this.stopLoading();
    },

    /**
     * 前のページ
     */
    async prevPage() {
      this.startLoading();
      await this.fetchRecords({
        ...this.query,
        ...{
          page: this.query.page - 1,
        },
      }, this.searchType);
      this.stopLoading();
    },

    /**
     * 次のページ
     */
    async nextPage() {
      this.startLoading();
      await this.fetchRecords({
        ...this.query,
        ...{
          page: this.query.page + 1,
        },
      }, this.searchType);
      this.stopLoading();
    },

    /**
     * 選択された記録を削除する
     */
    async deleteRecords() {
      this.startLoading();

      // メッセージクリア
      this.flashMessages.type = '';
      this.flashMessages.messages = [];
      this.successMessages = [];
      this.errorMessages = [];
      this.modalErrorMessages = [];

      // 記録削除APIを呼び出す
      const { status, data } = await deleteRecordsApi({ summary_ids: this.checkedIds });

      // 業務エラーまたはバリデーションエラーの場合はエラーメッセージを設定して処理終了
      if (status == 400 || status == 422) {
        this.errorMessages = data.detail.map(item => item.msg);
        // 削除確認モーダルを閉じる
        this.$refs.modalVoiceDelete.hideModal();
        this.stopLoading();
        return;
      }

      // 記録一覧を再検索（ページ数は１ページ目。検索条件と並び順は引き継ぐ）
      await this.fetchRecords({
        ...this.query,
        ...{
          page: 1,
        },
      }, this.searchType);

      // 成功メッセージを表示する
      this.successMessages = data.detail.map(item => item.msg);
      // 削除確認モーダルを閉じる
      this.$refs.modalVoiceDelete.hideModal();

      this.stopLoading();
    },

    /**
     * 記録詳細画面に遷移する
     * @param {*} id 記録サマリーID
     */
    toDetailPage(id) {
      const index = this.result.items.findIndex((item) => item.voice_file_summary_id === id);
      const currentIndex = (this.query.page - 1) * 50 + index + 1;
      this.setCurrentIndex({ currentIndex });
      this.$router.push({ name: 'VDX110', params: { id } });
    },

    /**
     * ダウンロード上限・業務チェック
     *
     * @param {*} downloadType ダウンロード種別
     */
    async checkDownloadData(downloadType) {
      this.startLoading();

      // メッセージクリア
      this.flashMessages.type = '';
      this.flashMessages.messages = [];
      this.successMessages = [];
      this.errorMessages = [];
      this.modalErrorMessages = [];

      // ダウンロードデータ上限フラグ,全て受信中フラグ,選択ダウンロードフラグを初期化
      this.isDownloadLimit = false;
      this.isAllReceiving = false;
      this.isSelectDownload = null;

      let status, data;
      if (downloadType == DOWNLOAD_TYPE.SELECT_DOWNLOAD) {
        this.isSelectDownload = true;
        // 選択ダウンロード上限・業務チェックAPIを呼び出す
        ({ status, data } = await checkSelectDownloadApi({ summary_ids: this.checkedIds }));
      } else {
        this.isSelectDownload = false;
        // 検索ダウンロード上限・業務チェックAPIを呼び出す
        ({ status, data } = await checkSearchDownloadApi(toSnakeCaseObject(this.query)));
      }

      // 業務エラーまたはバリデーションエラーの場合はエラーメッセージを設定して処理終了
      if (status == 400 || status == 422) {
        this.errorMessages = data.detail.map(item => item.msg);
        this.stopLoading();
        return;
      }

      // 上限チェックエラーもしくは全て受信中の場合はダウンロードデータ作成不可モーダルを開く
      if (data.is_download_limit || data.is_all_receiving) {
        this.isDownloadLimit = data.is_download_limit;
        this.isAllReceiving = data.is_all_receiving;
        this.$refs.modalDownloadUnableCreation.openModal();
        this.stopLoading();
        return;
      }

      // モーダルメッセージクリア
      this.modalDownloadConfirmCreationMessages = '';

      // モーダルメッセージを設定
      this.modalDownloadConfirmCreationMessages = data.detail[0].msg;
      // ダウンロードデータ作成確認モーダルを開く
      this.$refs.modalDownloadConfirmCreation.openModal();

      this.stopLoading();
    },

    /**
     * ダウンロードデータ作成
     */
    async createDownloadData() {
      this.startLoading();

      // メッセージクリア
      this.flashMessages.type = '';
      this.flashMessages.messages = [];
      this.successMessages = [];
      this.errorMessages = [];
      this.modalErrorMessages = [];

      // ダウンロードデータ上限フラグと全て受信中フラグを初期化
      this.isDownloadLimit = false;
      this.isAllReceiving = false;

      let status, data;
      if (this.isSelectDownload) {
        // 選択ダウンロードデータ作成APIを呼び出す
        ({ status, data } = await createSelectDownloadApi({ summary_ids: this.checkedIds }));
      } else {
        // 検索ダウンロードデータ作成APIを呼び出す
        ({ status, data } = await createSearchDownloadApi(toSnakeCaseObject(this.query)));
      }
      
      // 業務エラーまたはバリデーションエラーの場合はエラーメッセージを設定して処理終了
      if (status == 400 || status == 422) {
        this.errorMessages = data.detail.map(item => item.msg);
        // ダウンロードデータ作成確認モーダルを閉じる
        this.$refs.modalDownloadConfirmCreation.hideModal();
        this.stopLoading();
        return;
      }

      // 上限チェックエラーもしくは全て受信中の場合はダウンロードデータ作成不可モーダルを開く
      if (data.is_download_limit || data.is_all_receiving) {
        this.isDownloadLimit = data.is_download_limit;
        this.isAllReceiving = data.is_all_receiving;
        // ダウンロードデータ作成確認モーダルを閉じる
        this.$refs.modalDownloadConfirmCreation.hideModal();
        // ダウンロードデータ作成不可モーダルを開く
        this.$refs.modalDownloadUnableCreation.openModal();
        this.stopLoading();
        return;
      }

      // ダウンロードデータ作成確認モーダルを閉じる
      this.$refs.modalDownloadConfirmCreation.hideModal();
      // ダウンロードデータ作成完了モーダルを開く
      this.$refs.modalDownloadCompleteCreation.openModal();

      this.stopLoading();
    },

    /**
     * ダウンロード作成完了モーダルを閉じる
     */
    async closeCompleteModal() {
      this.startLoading();

      // 記録一覧を再検索（ページ数は１ページ目。検索条件と並び順は引き継ぐ）
      await this.fetchRecords({
        ...this.query,
        ...{
          page: 1,
        },
      }, this.searchType);

      this.stopLoading();
    },

    /**
     * ダウンロードデータ作成状況画面へ遷移
     */
    moveDownloadDataPage() {
      this.$router.push({ name: 'VDX300' });
    },
  },
};
</script>

<style lang="scss" scoped>
.layout-main {
  :deep(.main) {
    display: flex;
    flex-direction: column;
  }
}

.main-header {
  padding: $spacing-xxs;
  padding-bottom: 0;
  > .message-area {
    margin-bottom: $spacing-xxs;
  }
  > .search-area {
    margin-left: auto;
  }
}

.table-list-voice {
  overflow: auto;
  :deep(.list-voice) {
    padding-bottom: 56px; // ActionBarBottomの高さ分余白を空ける
  }
}
</style>
