<template>
  <div
    class="bottom-action-bar"
    data-test="action-bar-bottom"
  >
    <span class="text">{{ text }}</span>
    <ButtonBlock>
      <Button
        type="normal"
        data-test="cancel-button"
        @click="$emit('cancel')"
      >
        キャンセル
      </Button>
      <Button
        v-if="(type === 'download-and-delete' || type === 'download') && canSelectDownload"
        type="normal"
        @click="$emit('download', DOWNLOAD_TYPE.SELECT_DOWNLOAD)"
      >
        ダウンロード
      </Button>
      <Button
        v-if="type === 'download-and-delete' || type === 'delete'"
        type="delete"
        data-test="delete-button"
        @click="$emit('delete')"
      >
        {{ btnText }}
      </Button>
      <Button
        v-if="type === 'restore'"
        data-test="restore-button"
        @click="$emit('restore')"
      >
        記録一覧に戻す
      </Button>
    </ButtonBlock>
  </div>
</template>

<script>
import Button from '@/components/button/Button';
import ButtonBlock from '@/components/button/ButtonBlock';
import { DOWNLOAD_TYPE } from '@/utils/Constants';

export default {
  components: {
    ButtonBlock,
    Button,
  },
  props: {
    type: {
      type: String,
      required: true,
      validator: (value) => {
        return ['download-and-delete', 'download', 'delete', 'restore'].includes(value);
      },
    },
    text: {
      type: String,
      default: '',
    },
    btnText: {
      type: String,
      default: '',
    },
    canSelectDownload: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['download', 'delete', 'restore', 'cancel'],
  computed: {
    DOWNLOAD_TYPE() {
      return DOWNLOAD_TYPE;
    },
  },
};
</script>

<style lang="scss" scoped>
.bottom-action-bar {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 57px; // ボタン40px + 8px + 8px + 上線1px
  background: $gray300;
  border-top: 1px solid $gray400;
  display: flex;
  justify-content: center;
  align-items: center;
  > .text {
    font: $sans-none-16;
    color: $black700;
    // color: $white100;
  }
  > .button-block {
    margin-left: $spacing-sm;
  }
}
</style>
