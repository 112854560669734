<template>
  <LayoutMain @refresh="init">
    <MessageBar
      v-if="flashMessages.messages.length > 0"
      :type="flashMessages.type"
      :messages="flashMessages.messages"
      class="mb-xs"
    />
    <PageHeading heading="アーカイブ設定" />
    <MessageBar
      type="info"
      :messages="[
        '選択した日付より古い通話記録をアーカイブします。',
      ]"
      class="mt-xxs"
    />
    <FormBlock
      label="アーカイブ開始期間"
      class="mt-sm"
    >
      <FormRadio
        v-model="selectValue"
        :options="options"
        label-key="label"
        value-key="value"
      />
    </FormBlock>
    <ButtonBlock class="mt-md">
      <Button
        size="inline"
        type="primary"
        :disabled="selectValue === currentValue"
        class="mt-md"
        @click="onClick"
      >
        変更を保存
      </Button>
    </ButtonBlock>
  </LayoutMain>
</template>

<script>
import Button from '@/components/button/Button';
import ButtonBlock from '@/components/button/ButtonBlock';
import MessageBar from '@/components/common/MessageBar';
import PageHeading from '@/components/common/PageHeading';
import FormBlock from '@/components/form/FormBlock';
import FormRadio from '@/components/form/FormRadio';
import LayoutMain from '@/components/layout/LayoutMain';
import { editArchiveSettingApi, getArchiveSettingApi } from '@/utils/ApiHelper';
import { mapActions } from 'vuex';

export default {
  components: {
    LayoutMain,
    FormRadio,
    FormBlock,
    Button,
    MessageBar,
    PageHeading,
    ButtonBlock,
  },
  data() {
    return {
      /**
       * アーカイブ開始期間の選択肢
       */
      options: [],

      /**
       * 現在のアーカイブ開始期間
       */
      currentValue: undefined,

      /**
       * 選択中のアーカイブ開始期間
       */
      selectValue: undefined,

      /**
       * 画面に表示するフラッシュメッセージ
       */
      flashMessages: {
        type: '',
        messages: [],
      },
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    ...mapActions(['startLoading', 'stopLoading']),

    /**
     * 初期表示
     */
    async init() {
      this.startLoading();

      // フラッシュメッセージクリア
      this.flashMessages.type = '';
      this.flashMessages.messages = [];

      // アーカイブ開始期間を設定
      await this.getArchiveSetting();

      this.stopLoading();
    },

    /**
     * アーカイブ開始期間取得
     */
    async getArchiveSetting() {
      // フラッシュメッセージクリア
      this.flashMessages.type = '';
      this.flashMessages.messages = [];

      // アーカイブ設定取得APIを呼び出す
      const { status, data } = await getArchiveSettingApi();

      // 業務エラーの場合はエラーメッセージを設定して処理終了
      if (status === 400) {
        this.flashMessages.type = 'error';
        this.flashMessages.messages = data.detail.map(item => item.msg);
        this.stopLoading();
        return;
      }

      // アーカイブ開始期間を設定
      this.options = data.archive_period_setting;
      this.currentValue = data.current_value;
      this.selectValue = data.current_value;
    },

    /**
     * 「変更を保存」ボタンのクリックイベント
     */
    onClick() {
      this.onUpdate();
    },

    /**
     * アーカイブ開始期間の変更
     */
    async onUpdate() {
      this.startLoading();

      // フラッシュメッセージクリア
      this.flashMessages.type = '';
      this.flashMessages.messages = [];

      // アーカイブ設定更新APIを呼び出す
      const { status, data } = await editArchiveSettingApi({ value: this.selectValue });

      // 業務エラーまたはバリデーションエラーの場合はエラーメッセージを設定して処理終了
      if (status === 400 || status === 422) {
        this.flashMessages.type = 'error';
        this.flashMessages.messages = data.detail.map(item => item.msg);
        this.stopLoading();
        return;
      }

      // 成功メッセージを設定
      this.flashMessages.type = 'success';
      this.flashMessages.messages = data.detail.map(item => item.msg);

      // 現在のアーカイブ設定を更新
      this.currentValue = this.selectValue;

      this.stopLoading();
    },
  },
};
</script>

<style lang="scss" scoped>
.layout-main {
  :deep(.main) {
    padding: $spacing-sm $spacing-md;
    overflow-y: scroll;
  }
}
</style>
