<template>
  <svg
    :class="{ 'is-disabled': disabled }"
    width="11"
    height="8"
    viewBox="0 0 11 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.75 8.00005C1.99167 8.00005 1.34375 7.73755 0.80625 7.21255C0.26875 6.68755 0 6.04588 0 5.28755C0 4.63755 0.195833 4.05838 0.5875 3.55005C0.979167 3.04172 1.49167 2.71672 2.125 2.57505C2.26667 1.97505 2.62083 1.40422 3.1875 0.862549C3.75417 0.320882 4.35833 0.0500488 5 0.0500488C5.275 0.0500488 5.51042 0.147965 5.70625 0.343799C5.90208 0.539632 6 0.775049 6 1.05005V4.07505L6.8 3.30005L7.5 4.00005L5.5 6.00005L3.5 4.00005L4.2 3.30005L5 4.07505V1.05005C4.36667 1.16672 3.875 1.47297 3.525 1.9688C3.175 2.46463 3 2.97505 3 3.50005H2.75C2.26667 3.50005 1.85417 3.67088 1.5125 4.01255C1.17083 4.35422 1 4.76672 1 5.25005C1 5.73338 1.17083 6.14588 1.5125 6.48755C1.85417 6.82922 2.26667 7.00005 2.75 7.00005H8.75C9.1 7.00005 9.39583 6.87922 9.6375 6.63755C9.87917 6.39588 10 6.10005 10 5.75005C10 5.40005 9.87917 5.10422 9.6375 4.86255C9.39583 4.62088 9.1 4.50005 8.75 4.50005H8V3.50005C8 3.10005 7.90833 2.72713 7.725 2.3813C7.54167 2.03547 7.3 1.74172 7 1.50005V0.337549C7.61667 0.629215 8.10417 1.06047 8.4625 1.6313C8.82083 2.20213 9 2.82505 9 3.50005C9.575 3.56672 10.0521 3.81463 10.4313 4.2438C10.8104 4.67297 11 5.17505 11 5.75005C11 6.37505 10.7812 6.9063 10.3438 7.3438C9.90625 7.7813 9.375 8.00005 8.75 8.00005H2.75Z"
      fill="#707076"
    />
  </svg>
</template>

<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.is-disabled path {
  fill: $black300;
}
</style>
