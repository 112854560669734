<template>
  <li class="listitem-download">
    <div class="item">
      <div
        class="textblock status"
        :class="downloadStatusValue"
      >
        {{ downloadStatusDisplay }}
      </div>
      <div class="textblock period">
        {{ extractPeriod }}
      </div>
      <div class="textblock number">
        {{ fileNumber }}
      </div>
      <div class="textblock user">
        {{ userName }}
      </div>
      <div class="textblock date">
        {{ requestDatetime }}
      </div>
      <div class="downlod-btn">
        <ButtonDownloadCloud
          v-if="downloadStatusValue == 'can_be_requested'"
          @click="onClick"
        />
        <ButtonDownloadZip
          v-else
          :disabled="isDownloadable"
          @click="onClick"
        />
      </div>
    </div>
  </li>
</template>

<script>
import ButtonDownloadCloud from '@/components/button/ButtonDownloadCloud';
import ButtonDownloadZip from '@/components/button/ButtonDownloadZip';

export default {
  components: {
    ButtonDownloadCloud,
    ButtonDownloadZip,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
    downloadStatusValue: {
      type: String,
      default: '',
      validator: (value) => {
        return ['can_be_requested', 'downloadable', 'prepare', 'failed'].includes(value);
      },
    },
    downloadStatusDisplay: {
      type: String,
      default: '',
    },
    extractPeriod: {
      type: String,
      default: '',
    },
    fileNumber: {
      type: String,
      default: '',
    },
    userName: {
      type: String,
      default: '',
    },
    requestDatetime: {
      type: String,
      default: '',
    },
    isDownloadable: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['click'],
  methods: {
    onClick() {
      this.$emit('click', this.id, this.downloadStatusValue);
    },
  },
};
</script>

<style lang="scss" scoped>
.listitem-download {
  border-bottom: 1px solid $gray400;
  background-color: $white100;
  position: relative;
  width: 100%;

  .item {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    text-decoration: none;

    > .status.failed {
      color: $red300;
    }

    > .textblock {
      font: $sans-none-14;
      color: $black700;
      height: 40px;
      line-height: 40px;
      padding-right: 16px;
    }

    > .status {
      width: 152px;
    }
    > .period {
      width: calc((100% - 520px) * 0.42);
    }
    > .number {
      width: 100px;
    }
    > .user {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: calc((100% - 520px) * 0.3);
    }
    > .date {
      width: 162px;
    }
    > .downlod-btn {
      margin: 0 $spacing-sm;
      width: calc((100% - 520px) * 0.28);
    }
  }
}
</style>
