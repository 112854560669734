<template>
  <Modal
    ref="modalDownloadConfirmRequest"
  >
    <div class="title">
      ダウンロード準備中
    </div>
    <p class="text">
      アーカイブデータの準備には最大2～3日かかる場合があります。準備が完了後ダウンロード可能になります。
    </p>
    <ButtonBlock>
      <Button
        size="inline"
        type="normal"
        @click="hideModal"
      >
        キャンセル
      </Button>
      <Button
        size="inline"
        @click="$emit('request', id)"
      >
        了解
      </Button>
    </ButtonBlock>
  </Modal>
</template>

<script>
import Button from '@/components/button/Button';
import ButtonBlock from '@/components/button/ButtonBlock';
import Modal from '@/components/common/Modal';

export default {
  components: {
    Button,
    ButtonBlock,
    Modal,
  },
  emits: ['request'],
  data() {
    return{
      id: null,
    };
  },
  methods: {
    /**
     * モーダル表示
     * @param {*} id
     */
    openModal(id) {
      this.id = id;
      this.$refs.modalDownloadConfirmRequest.openModal();
    },
    /**
     * モーダル非表示
     */
    hideModal() {
      this.$refs.modalDownloadConfirmRequest.hideModal();
    },
  },
};
</script>
