<template>
  <div class="chat-list">
    <ChatListItem
      v-for="(item, index) of items"
      :key="index"
      :speech-order="item.speech_order"
      :speech-phone-number="item.speech_phone_number"
      :speech-phone-name="item.speech_phone_name"
      :calling-incoming-type="item.calling_incoming_type"
      :voice-text="item.voice_text"
      :original-text="item.original_text"
      :edit-log="item.edit_log"
      :is-editing="item.speech_order === editingParagraph"
      :is-editable="isEditable"
      :is-show-edit-button="isShowEditButton"
      :voice-part-start-time="item.voice_part_start_time"
      :audio-player-id="audioPlayerId"
      @edit="onEdit"
      @cancel="onCancel"
      @save="onSave"
    />
  </div>
</template>

<script>
import ChatListItem from '@/components/voice/ChatListItem';

export default {
  components: {
    ChatListItem,
  },
  props: {
    items: {
      type: Array,
      default: () => {},
    },
    isEditable: {
      type: Boolean,
      default: false,
    },
    isShowEditButton: {
      type: Boolean,
      default: false,
    },
    audioPlayerId: {
      type: String,
      default: '',
    },
  },
  emits: ['edit', 'cancel', 'save'],
  data() {
    return {
      editingParagraph: undefined, // １箇所しか編集できないことをここで保証
    };
  },
  methods: {
    onEdit(speechOrder) {
      this.editingParagraph = speechOrder;
      this.$emit('edit', speechOrder);
    },
    onCancel() {
      this.editingParagraph = undefined;
      this.$emit('cancel');
    },
    onSave(speechOrder, editText) {
      this.$emit('save', speechOrder, editText);
    },
    endEdit() {
      this.editingParagraph = undefined;
    },
  },
};
</script>

<style lang="scss" scoped>
.chat-list {
  > .chatlist-item + .chatlist-item {
    margin-top: $spacing-sm;
  }
}
</style>
