<template>
  <button
    class="button-download-cloud"
    :class="{ 'is-disabled': disabled }"
    :disabled="disabled"
    @click="click"
  >
    <IconDownloadCloud
      :disabled="disabled"
    />
    <span class="label">
      DLリクエスト
    </span>
  </button>
</template>

<script>
import IconDownloadCloud from '@/components/icons/IconDownloadCloud';

export default {
  components: {
    IconDownloadCloud,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['click'],
  methods: {
    click() {
      this.$emit('click');
    },
  },
};
</script>

<style lang="scss" scoped>
.button-download-cloud {
  position: relative;
  display: flex;
  align-items: center;
  height: 24px;
  width: 110px;
  padding: 6px 0px 6px 10px;
  margin: 0;
  border-radius: 12px;
  border-width: 1px;
  border-style: solid;
  border-color: $black500;
  background: $white100;
  letter-spacing: -0.04em;

  &.is-disabled {
    background-color: $gray500;
    border-color: $gray500;

    > .label {
      color: $black400;
    }
  }

  > .label {
    font: $sans-none-12;
    color: $black500;
    margin-left: 6px;
  }
}
</style>
