<template>
  <div class="list-download">
    <div
      v-if="items.length > 0"
      class="header"
    >
      <div class="header-item status">
        ステータス
      </div>
      <div class="header-item period">
        検索期間
      </div>
      <div class="header-item number">
        ファイル数
      </div>
      <div class="header-item user">
        リクエストユーザー
      </div>
      <div class="header-item date">
        リクエスト日時
      </div>
      <div class="header-item download-btn" />
    </div>
    <ul
      v-if="items.length > 0"
      ref="scrollable"
      class="list"
    >
      <ListItemDownload
        v-for="item of items"
        :id="item.archive_download_id"
        :key="item.archive_download_id"
        :download-status-value="item.download_status_value"
        :download-status-display="item.download_status_display"
        :extract-period="item.extract_period"
        :file-number="item.file_number"
        :user-name="item.request_user"
        :request-datetime="item.request_datetime"
        :is-downloadable="!item.is_downloadable"
        @click="onClick"
      />
    </ul>
    <p
      v-if="items.length === 0"
      class="not-found"
    >
      表示するデータがありません
    </p>
  </div>
</template>

<script>
import ListItemDownload from '@/components/archive/ListItemDownload';

export default {
  components: {
    ListItemDownload,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['request','download'],
  watch: {
    /**
     * propsのitemsが更新されたら実行される
     */
    items() {
      // 一覧のスクロールを最上部に戻す
      if (this.$refs.scrollable) {
        this.$refs.scrollable.scrollTop = 0;
      }
    },
  },
  methods: {
    onClick(id, status) {
      if(status == 'can_be_requested'){
        this.$emit('request', id);
      }else{
        this.$emit('download', id);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.list-download {
  display: flex;
  flex-direction: column;
  overflow: auto;
  margin: 0 24px;
  > .header {
    display: flex;
    background-color: $white100;
    border-bottom: 2px solid $gray400;
    overflow-y: scroll;
    min-height: 31px;
    &::-webkit-scrollbar{
      width: 6px;
    }
    &::-webkit-scrollbar-track,
    &::-webkit-scrollbar-thumb{
      background: none;
    }

    > .header-item  {
      color: $black400;
      font: $sans-none-12-bold;
      height: 29px;
      line-height: 29px;
      padding-right: 16px;
    }

    > .status {
      width: 152px;
    }
    > .period {
      width: calc((100% - 520px) * 0.42);
    }
    > .number {
      width: 100px;
    }
    > .user {
      width: calc((100% - 520px) * 0.3);
    }
    > .date {
      width: 162px;
    }
    > .download-btn {
      width: calc((100% - 520px) * 0.28);
    }
  }

  > .list {
    overflow-y: scroll;
    padding-bottom: 56px;
    &::-webkit-scrollbar{
      width: 6px;
    }
    &::-webkit-scrollbar-track{
      background-color: none;
    }
    &::-webkit-scrollbar-thumb{
      border-radius: 20px;
      background-color: $gray600;
    }
  }

  > .not-found {
    margin: 0;
    font: $sans-normal-14;
    color: $black700;
  }
}
</style>
