import VDX010 from '@/views/VDX010.vue';
import VDX020 from '@/views/VDX020.vue';
import VDX050 from '@/views/VDX050.vue';
import VDX051 from '@/views/VDX051.vue';
import VDX060 from '@/views/VDX060.vue';
import VDX070 from '@/views/VDX070.vue';
import VDX100 from '@/views/VDX100.vue';
import VDX110 from '@/views/VDX110.vue';
import VDX200 from '@/views/VDX200.vue';
import VDX210 from '@/views/VDX210.vue';
import VDX300 from '@/views/VDX300.vue';
import VDX400 from '@/views/VDX400.vue';
import VDX410 from '@/views/VDX410.vue';
import VDX500 from '@/views/VDX500.vue';
import VDX510 from '@/views/VDX510.vue';
import VDX600 from '@/views/VDX600.vue';
import VDX610 from '@/views/VDX610.vue';
import VDX700 from '@/views/VDX700.vue';
import VDX800 from '@/views/VDX800.vue';
import VDX810 from '@/views/VDX810.vue';
import VDX820 from '@/views/VDX820.vue';
import VDX900 from '@/views/VDX900.vue';
import VDX910 from '@/views/VDX910.vue';
import VDX920 from '@/views/VDX920.vue';
import VDX930 from '@/views/VDX930.vue';
import VDXA00 from '@/views/VDXA00.vue';
import VDXA10 from '@/views/VDXA10.vue';
import VDXB00 from '@/views/VDXB00.vue';
import VDXC00 from '@/views/VDXC00.vue';
import VDXD00 from '@/views/VDXD00.vue';
import VDXD10 from '@/views/VDXD10.vue';
import VDXD20 from '@/views/VDXD20.vue';
import VDXE00 from '@/views/VDXE00.vue';
import VDXE10 from '@/views/VDXE10.vue';
import { createRouter, createWebHistory } from 'vue-router';

const routes = [
  {
    path: '/activate',
    name: 'VDX010',
    component: VDX010,
    meta: { title: 'アクティベート' },
  },
  {
    path: '/signup',
    name: 'VDX020',
    component: VDX020,
    meta: { title: '初期登録' },
  },
  {
    path: '/login',
    name: 'VDX050',
    component: VDX050,
    meta: { title: 'ログイン' },
  },
  {
    path: '/login/saml',
    name: 'VDX051',
    component: VDX051,
    meta: { title: 'ログイン' },
  },
  {
    path: '/password_reset',
    name: 'VDX060',
    component: VDX060,
    meta: { title: 'パスワード再設定依頼' },
  },
  {
    path: '/settings/password',
    name: 'VDX070',
    component: VDX070,
    meta: { title: 'パスワード再設定' },
  },
  {
    path: '/record',
    name: 'VDX100',
    component: VDX100,
    meta: { title: '記録一覧' },
  },
  {
    path: '/record/:id',
    name: 'VDX110',
    component: VDX110,
    meta: { title: '記録詳細' },
  },
  {
    path: '/trash',
    name: 'VDX200',
    component: VDX200,
    meta: { title: 'ゴミ箱' },
  },
  {
    path: '/trash/:id',
    name: 'VDX210',
    component: VDX210,
    meta: { title: 'ゴミ箱詳細' },
  },
  {
    path: '/download',
    name: 'VDX300',
    component: VDX300,
    meta: { title: 'ダウンロードデータ作成状況' },
  },
  {
    path: '/group',
    name: 'VDX400',
    component: VDX400,
    meta: { title: 'グループ一覧' },
  },
  {
    path: '/group/:id/edit',
    name: 'VDX410',
    component: VDX410,
    meta: { title: 'グループ編集' },
  },
  {
    path: '/user',
    name: 'VDX500',
    component: VDX500,
    meta: { title: 'ユーザー一覧' },
  },
  {
    path: '/user/:id/edit',
    name: 'VDX510',
    component: VDX510,
    meta: { title: 'ユーザー編集' },
  },
  {
    path: '/usage/current',
    name: 'VDX600',
    component: VDX600,
    meta: { title: '今月の利用状況' },
  },
  {
    path: '/usage/past',
    name: 'VDX610',
    component: VDX610,
    meta: { title: '過去の利用状況' },
  },
  {
    path: '/auto-delete',
    name: 'VDX700',
    component: VDX700,
    meta: { title: '自動削除' },
  },
  {
    path: '/keyword',
    name: 'VDX800',
    component: VDX800,
    meta: { title: 'キーワード一覧' },
  },
  {
    path: '/keyword/create',
    name: 'VDX810',
    component: VDX810,
    meta: { title: 'キーワード登録' },
  },
  {
    path: '/keyword/:id/edit',
    name: 'VDX820',
    component: VDX820,
    meta: { title: 'キーワード編集' },
  },
  {
    path: '/notice/keyword',
    name: 'VDXA00',
    component: VDXA00,
    meta: { title: 'キーワード通知一覧' },
  },
  {
    path: '/notice/keyword/:id',
    name: 'VDXA10',
    component: VDXA10,
    meta: { title: 'キーワード通知詳細' },
  },
  {
    path: '/sso',
    name: 'VDXB00',
    component: VDXB00,
    meta: { title: 'SSO設定' },
  },
  {
    path: '/log',
    name: 'VDXC00',
    component: VDXC00,
    meta: { title: 'ログ管理' },
  },
  {
    path: '/supplier',
    name: 'VDXD00',
    component: VDXD00,
    meta: { title: '取引先一覧' },
  },
  {
    path: '/supplier/create',
    name: 'VDXD10',
    component: VDXD10,
    meta: { title: '取引先登録' },
  },
  {
    path: '/supplier/:id/edit',
    name: 'VDXD20',
    component: VDXD20,
    meta: { title: '取引先編集' },
  },
  {
    path: '/archive',
    name: 'VDXE00',
    component: VDXE00,
    meta: { title: 'アーカイブ設定' },
  },
  {
    path: '/archive/download',
    name: 'VDXE10',
    component: VDXE10,
    meta: { title: 'アーカイブダウンロード' },
  },
  {
    path: '/500',
    name: 'VDX910',
    component: VDX910,
    meta: { title: 'システムエラー' },
  },
  {
    path: '/504',
    name: 'VDX920',
    component: VDX920,
    meta: { title: 'タイムアウト' },
  },
  {
    path: '/403',
    name: 'VDX930',
    component: VDX930,
    meta: { title: '権限がありません' },
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'VDX900',
    component: VDX900,
    meta: { title: 'このページは存在しません' },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
