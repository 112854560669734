export const RECORD_SEARCH_TYPE = Object.freeze({
  FULL_TEXT: 1,
  DETAIL: 2,
});

export const DOWNLOAD_TYPE = Object.freeze({
  SELECT_DOWNLOAD: 'SELECT_DOWNLOAD',
  SEARCH_DOWNLOAD: 'SEARCH_DOWNLOAD',
});

export const ERROR_MESSAGES = Object.freeze({
  UNAUTHORIZED_LOGIN: 'FE400-001 : メールアドレスまたはパスワードが違います',
  AUDIO_CANNOT_PLAY: 'FE400-002 : 音声を再生することができません',
  SSO_LOGIN_FAILED: 'FE400-003 : SSOログインに失敗しました',
  SSO_CONNECT_FAILED: 'FE400-004 : SSO接続に失敗しました',
  UNAUTHORIZED_API: 'FE401-001 : ログインの有効期限が過ぎました。もう一度ログインをお願いいたします',
});

export const APP_SERVICE_NAME = 'KDDI Voice Viewer';

export const API_IDS = Object.freeze({
  SEARCH_RECORD_LIST: 'SEARCH_RECORD_LIST', // 通常検索、詳細検索API
  GET_LINECONTRACT_LIST: 'GET_LINECONTRACT_LIST', // 回線契約管理番号取得API
  DELETE_RECORD_LIST: 'DELETE_RECORD_LIST', // 記録削除API
  GET_RECORD: 'GET_RECORD', // 記録詳細情報取得API
  DOWNLOAD_RECORD_CSV: 'DOWNLOAD_RECORD_CSV', // CSVダウンロードAPI
  DOWNLOAD_RECORD_WAV: 'DOWNLOAD_RECORD_WAV', // 音声ダウンロードURL取得API
  UPDATE_RECORD_TEXT: 'UPDATE_RECORD_TEXT', // 文字起こしテキスト編集API
  RESTORE_TRASH_LIST: 'RESTORE_TRASH_LIST', // 記録削除取消API
  GET_USER_LIST: 'GET_USER_LIST', // ユーザ一覧検索API
  SEND_USER_INVITE: 'SEND_USER_INVITE', // 招待メール送信API
  GET_USER: 'GET_USER', // ユーザ情報取得API
  SEND_USER_PASSWORD_RESET: 'SEND_USER_PASSWORD_RESET', // パスワード再設定メール送信API
  DELETE_USER: 'DELETE_USER', // ユーザ削除API
  UPDATE_USER: 'UPDATE_USER', // ユーザ更新API
  GET_USAGE_CURRENT: 'GET_USAGE_CURRENT', // 今月の利用状況取得API
  GET_USAGE_PAST: 'GET_USAGE_PAST', // 過去の利用状況取得API
  GET_SETTING_AUTODELETE: 'GET_SETTING_AUTODELETE', // 自動削除設定取得API
  UPDATE_SETTING_AUTODELETE: 'UPDATE_SETTING_AUTODELETE', // 自動削除設定更新API
  GET_DOWNLOAD_DATA: 'GET_DOWNLOAD_DATA', // ダウンロードデータ作成状況取得API
  DELETE_DOWNLOAD_DATA: 'DELETE_DOWNLOAD_DATA', // ダウンロードデータ削除API
  GET_DOWNLOAD_URL: 'GET_DOWNLOAD_URL', // ダウンロードURL取得API
  DOWNLOAD_SELECT: 'DOWNLOAD_SELECT', // 選択ダウンロード作成API
  DOWNLOAD_SEARCH: 'DOWNLOAD_SEARCH', // 検索ダウンロード作成API
  CREATE_KEYWORD: 'CREATE_KEYWORD', // キーワード作成API
  DELETE_KEYWORD: 'DELETE_KEYWORD', // キーワード削除API
  EDIT_KEYWORD: 'EDIT_KEYWORD', // キーワード編集API
  EDIT_IDP_SETTING: 'EDIT_IDP_SETTING', // IdP設定更新API
  CREATE_SUPPLIER: 'CREATE_SUPPLIER', // 取引先登録API
  DELETE_SUPPLIER: 'DELETE_SUPPLIER', // 取引先削除API
  EDIT_SUPPLIER: 'EDIT_SUPPLIER', // 取引先編集API
  GET_ARCHIVE_DOWNLOAD: 'GET_ARCHIVE_DOWNLOAD', // アーカイブ音声ファイルダウンロードデータ作成状況取得API
  GET_ARCHIVE_DOWNLOAD_URL: 'GET_ARCHIVE_DOWNLOAD_URL', // アーカイブ音声ファイルダウンロードURL取得API
  ARCHIVE_DOWNLOAD: 'ARCHIVE_DOWNLOAD', // アーカイブ音声ファイルダウンロードデータ作成API
  ARCHIVE_SEARCH: 'ARCHIVE_SEARCH', // アーカイブ音声ファイル取り出しAPI
});

export const USER_ROLE_TYPE = Object.freeze({
  ADMINISTRATOR: 'ADMINISTRATOR', // 管理者
  VIEWER: 'VIEWER', // 閲覧者
  BASE_ADMIN: 'BASE_ADMIN', // 拠点管理者
  BASE_VIEWER: 'BASE_VIEWER', // 拠点閲覧者
});

export const COGNITO_CONFIG = Object.freeze({
  Auth: {
    region: process.env.VUE_APP_COGNITO_REGION,
    userPoolId: process.env.VUE_APP_COGNITO_USER_POOL_ID,
    userPoolWebClientId : process.env.VUE_APP_COGNITO_CLIENT_ID,
    // Hosted UI configuration
    oauth: {
      domain: process.env.VUE_APP_COGNITO_OAUTH_DOMAIN,
      scope: ['openid', 'aws.cognito.signin.user.admin'],
      redirectSignIn: process.env.VUE_APP_COGNITO_OAUTH_REDIRECT_SIGNIN,
      redirectSignOut: process.env.VUE_APP_COGNITO_OAUTH_REDIRECT_SIGNOUT,
      responseType: 'code',
    },
  },
});
